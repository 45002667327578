import Axios from 'axios';
import {mask} from 'vue-the-mask';
import moment from 'moment';
import Vue from 'vue';
import TextareaAutosize from 'vue-textarea-autosize'

Vue.use(TextareaAutosize);

export default {
  directives: {mask},
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      banners: [],
      banner: {},
      testimonies: [],
      testimonial: {},
      clients: [],
      client: {},
      collapse: {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
        seven: false,
      },
      syncing: false,
      form: {},
      errors: {},
      inputs: [],
      // lixo
      specialities: [],
    }
  },
  mounted() {

    jQuery(document).on("input", "#bio", function() {
      var limite = 185;
      var caracteresDigitados = jQuery(this).val().length;
      var caracteresRestantes = limite - caracteresDigitados;

      if (caracteresRestantes <= 0) {
        var comentario = jQuery("textarea[name=bio]").val();
        jQuery("textarea[name=bio]").val(comentario.substr(0, limite));
        jQuery(".caracteres").text("(0)");
      } else {
        jQuery(".caracteres").text("(" + caracteresRestantes + ")");
      }
    });

    jQuery('#bio').bind('copy paste', function (e) {
      e.preventDefault();
    });

    var _this = this;

    if (_this.$store.state.role == 'client') _this.$router.push({ path: '/dashboard/client' });
    if (_this.$store.state.role == 'professional') _this.$router.push({ path: '/dashboard/professional' });

    axios
    .get('/api/v1/users/' + this.me.id, {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        if (!response.data.data.profile) response.data.data.profile = {};
        if (!response.data.data.financial) response.data.data.financial = {};
        this.form = response.data.data;
        this.form.password = '';
        let index = this.form.specialities.map(function(e) { return e.pt; }).indexOf(this.form.profile.major);
        if (index > -1) this.form.specialities.splice(index, 1);
        this.form.profile.nascimento = moment(this.form.profile.nascimento).format('YYYY-MM-DD');
        this.selectedBank = this.form.financial.bank_id;
      }
    });

    axios
    .get('/api/v1/banners', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.banners = response.data.data;
      }
    });

    axios
    .get('/api/v1/testimonies', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.testimonies = response.data.data;
      }
    });

    axios
    .get('/api/v1/clients', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.clients = response.data.data;
      }
    });

    axios
    .get('/api/v1/states', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.states = response.data.data;
      }
    });

    axios
    .get('/api/v1/cities', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.cities = response.data.data;
      }
    });

  },
  methods: {

    destroyBanner(index) {
      let _this = this;
      let id = _this.banners[index].id;
      let confirm = window.confirm('Deseja remover esse banner?');
      if (!confirm) return;

      axios
      .delete('/api/v1/banners/' + id, {
        headers: {
          Authorization: 'Bearer ' + _this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          _this.banners.splice(index, 1);
        }
      });
    },

    uploadBanner() {
      let _this = this;
      let formData = new FormData();
      let image = document.querySelector("#banner-file");
      formData.append("image", image.files[0]);
      formData.append("author", this.banner.author);
      formData.append("description", this.banner.description);
      axios.post("/api/v1/banners", formData, {
       headers: {
         Authorization: 'Bearer ' + this.TOKEN,
         "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
       }
      }).then(response => {
        if (!response.data.error) {

          _this.banners.push({
            id: response.data.data.id,
            source: response.data.data.source,
            author: _this.banner.author,
            description: _this.banner.description,
          });

          _this.banner.author = "";
          _this.banner.description = "";

        }
      });
    },

    destroyTestimonial(index) {
      let _this = this;
      let id = _this.testimonies[index].id;
      let confirm = window.confirm('Deseja remover esse depoimento?');
      if (!confirm) return;

      axios
      .delete('/api/v1/testimonies/' + id, {
        headers: {
          Authorization: 'Bearer ' + _this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          _this.testimonies.splice(index, 1);
        }
      });
    },

    uploadTestimonial() {
      let _this = this;
      let formData = new FormData();
      let image = document.querySelector("#testimonial-file");
      formData.append("image", image.files[0]);
      formData.append("name", this.testimonial.name);
      formData.append("description", this.testimonial.description);
      formData.append("body", this.testimonial.body);
      axios.post("/api/v1/testimonies", formData, {
       headers: {
         Authorization: 'Bearer ' + this.TOKEN,
         "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
       }
      }).then(response => {
        if (!response.data.error) {

          _this.testimonies.push({
            id: response.data.data.id,
            source: response.data.data.source,
            name: _this.testimonial.name,
            description: _this.testimonial.description,
            body: _this.testimonial.body,
          });

          _this.testimonial.name = "";
          _this.testimonial.description = "";
          _this.testimonial.body = "";

        }
      });
    },

    destroyClient(index) {
      let _this = this;
      let id = _this.clients[index].id;
      let confirm = window.confirm('Deseja remover esse cliente?');
      if (!confirm) return;

      axios
      .delete('/api/v1/clients/' + id, {
        headers: {
          Authorization: 'Bearer ' + _this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          _this.clients.splice(index, 1);
        }
      });
    },

    uploadClient() {
      let _this = this;
      let formData = new FormData();
      let image = document.querySelector("#client-file");
      formData.append("image", image.files[0]);
      axios.post("/api/v1/clients", formData, {
       headers: {
         Authorization: 'Bearer ' + this.TOKEN,
         "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
       }
      }).then(response => {
        if (!response.data.error) {
          _this.clients.push({
            id: response.data.data.id,
            source: response.data.data.source,
          });
        }
      });
    },

    addRow() {
      this.inputs.push({
        one: '',
        two: ''
      })
    },
    deleteRow(index) {
      this.inputs.splice(index,1)
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    validateMainSpeciality: function(e) {
      let text = this.mainSpecialityInput;
      let index = this.specialities.map(function(e) { return e.pt; }).indexOf(text);
      // faz parte da lista de habilidades
      if (index > -1) {
        this.form.profile.major = text;
      }
      this.mainSpecialityInput = '';
    },

    validateListComplementares: function(e) {
      if (this.form.specialities.length < 5) {
        let text = this.specialitiesInput;
        let index = this.specialities.map(function(e) { return e.pt; }).indexOf(text);
        let double = this.form.specialities.indexOf(text);
        // faz parte da lista de habilidades
        if (index > -1) {
          // não está ainda na lista selecionada de habilidades
          if (double < 0) this.form.specialities.push(this.specialities[index]);
        }
      }
      this.specialitiesInput = '';
    },

    removeSpeciality: function(s) {
      // remove habilidade do briefing
      let index = this.form.specialities.indexOf(s);
      if (index > -1) {
        this.form.specialities.splice(index, 1);
      }
    },

    setBankId(e) {
      let index = this.banks.map(function(e) { return e.name; }).indexOf(this.bankInput);
      if (index > -1) this.selectedBank = this.banks[index].id;
    },

    handleAvatarUpload() {
      this.avatarFile = this.$refs.avatar.files[0];
    },

    submit() {
      let _this = this;
      _this.syncing = true;
      let data = {
        specialities: [],
      };
      var formData = new FormData();
      let form = _this.form;
      // Perfil
      if (form.profile.bio != null) formData.set("bio", form.profile.bio);
      else formData.set("bio", '');
      // Dados pessoais
      if (form.name != null) formData.set("name", form.name);
      if (form.last_name != null) formData.set("last_name", form.last_name);
      else formData.set("last_name", '');
      if (form.profile.rg != null) formData.set("rg", form.profile.rg);
      else formData.set("rg", '');
      if (form.profile.cpf != null) formData.set("cpf", form.profile.cpf);
      else formData.set("cpf", '');
      if (form.profile.nascimento && form.profile.nascimento != null) formData.set("born_date", form.profile.nascimento);
      if (form.profile.country_id) formData.set("country", form.profile.country_id);
      data.state = form.profile.country_id == 3 ? form.profile.state_id : form.profile.state_open;
      if (data.state) formData.set("state", data.state);
      data.city = form.profile.country_id == 3 ? form.profile.city_id : form.profile.city_open;
      if (data.city) formData.set("city", data.city);
      // Dados bancários
      if (form.legal.cnpj != null) formData.set("cnpj", form.legal.cnpj);
      else formData.set("cnpj", '');
      if (_this.selectedBank != null) formData.set("bank", _this.selectedBank);
      if (form.financial.type != null) formData.set("type", form.financial.type);
      else formData.set("type", '');
      if (form.financial.razao != null) formData.set("razao", form.financial.razao);
      else formData.set("razao", '');
      if (form.financial.agency != null) formData.set("agency", form.financial.agency);
      else formData.set("agency", '');
      if (form.financial.account != null) formData.set("account", form.financial.account);
      else formData.set("account", '');
      // Login Creators
      if (form.email && form.email != _this.me.email) formData.set("email", form.email);
      if (_this.formPassword && _this.formPassword.length) formData.set("password", _this.formPassword);
      if (this.avatarFile) formData.append('avatar', this.avatarFile);

      formData.append('_method', 'PUT');
      axios
      .post('/api/v1/users/' + _this.me.id, formData, {
        headers: {
          Authorization: 'Bearer ' + _this.TOKEN,
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(response => {
        // console.log('retorno', response.data);
        if (response.data.error) {
          _this.errors = response.data.error;
          _this.syncing = false;
        } else {
          _this.$store.commit('setMe', response.data.data);
          _this.$router.push({ path: '/dashboard/professional' });
        }
      });
    },

    logout() {
      axios
      .get('/api/auth/logout', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (response.data.error) {
          this.errors = response.data.error;
        } else {
          this.$store.commit('setToken', '');
          this.$store.commit('setMe', '');
          this.$router.push({ path: '/' });
        }
      });
    },

    deleteAccount() {
      axios
      .delete('/api/v1/users/' + this.me.id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (response.data.error) {
          this.errors = response.data.error;
        } else {
          $('#delete-account-modal').modal('hide');
          this.$store.commit('setToken', '');
          this.$store.commit('setMe', '');
          this.$router.push({ path: '/' });
        }
      });
    },

    //Show Password
    showPassword() {
      var x = document.getElementById("password");
      jQuery('.ico-eye').toggleClass('show');
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },

    handleCollapse(index) {
      switch (index) {
        case 1:
        this.collapse.one = !this.collapse.one;
        this.collapse.two = this.collapse.three = this.collapse.four = this.collapse.five = this.collapse.six = false;
        break;
        case 2:
        this.collapse.two = !this.collapse.two;
        this.collapse.one = this.collapse.three = this.collapse.four = this.collapse.five = this.collapse.six = false;
        break;
        case 3:
        this.collapse.three = !this.collapse.three;
        this.collapse.one = this.collapse.two = this.collapse.four = this.collapse.five = this.collapse.six = false;
        break;
        case 4:
        this.collapse.four = !this.collapse.four;
        this.collapse.one = this.collapse.two = this.collapse.three = this.collapse.five = this.collapse.six = false;
        break;
        case 5:
        this.collapse.five = !this.collapse.five;
        this.collapse.one = this.collapse.two = this.collapse.three = this.collapse.four = this.collapse.six = false;
        break;
        case 6:
        this.collapse.six = !this.collapse.six;
        this.collapse.one = this.collapse.two = this.collapse.three = this.collapse.four = this.collapse.five = false;
        break;
        case 7:
        this.collapse.seven = !this.collapse.seven;
        this.collapse.one = this.collapse.two = this.collapse.three = this.collapse.four = this.collapse.five = this.collapse.six = false;
        break;
        default:
        this.collapse.one = this.collapse.two = this.collapse.three = this.collapse.four = this.collapse.five = this.collapse.six = true;
        break;
      }
    },

  },

  computed: {
    avatar() {
      return (this.form.avatar) ? '/' + this.form.avatar : '/images/avatar.jpg';
    },
  }

}
